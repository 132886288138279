import images from '../../../common/images'

export const leaderships = [
  {
    company: 'IVA株式会社',
    profile: images.ceoProfile,
    title: '代表取締役CEO',
    name: '相原 嘉夫',
    position: '代表取締役 執行役員CEO',
    intro:
      '1995年生まれ。大学時代に起業し事業譲渡を経験。2019年に成長を続けるファッション市場と、市場の抱える「フェイク問題」という巨大なペインに目をつけ、ヒトとAIを掛け合わせた真贋鑑定サービス「フェイクバスターズ」を立ち上げ、IVA株式会社を設立。2023年 Forbes 30 Under 30 Japan、2024年 Forbes 30 Under 30 Asiaに選出。日本語・中国語のバイリンガル。'
  },
  {
    company: 'IVA株式会社',
    profile: images.cooProfile,
    title: '取締役 執行役員COO 兼 中国法人代表',
    name: 'ジェイ・ルー',
    position: '取締役 執行役員COO 兼 中国法人代表',
    intro:
      '1995年生まれ。広東外語外貿大学(GUFS)で、中国市場、経営管理を研究後、来日。2019年 IVA株式会社を相原と共に創業し、COOとして事業戦略、組織管理、中国開発拠点の立ち上げを牽引するなど幅広い領域で会社のグロースをサポート。中国語・日本語のバイリンガル。'
  },
  {
    company: 'IVA株式会社',
    profile: images.ctoProfile,
    title: '執行役員CTO',
    name: 'ネルソン・チェン',
    position: '執行役員CTO',
    intro:
        '中国文化大学情報通信学院修士課程を卒業後、台湾のIT企業に入社。「中国信託」「ZYXEL」「台湾セイコー」および数十件の企業プロジェクトの実行と管理を経験その後、台湾政府観光局や科学技術研究院などの国営企業のプロジェクト開発に、UI/UXコンサルタントとしても参画した経験があります。2019年に親交のあった相原に誘われ、IVA株式会社に参画。CTOとしてプロダクトの立ち上げを主導し、台湾と中国の開発チームのマネジメントを担当。'
  },
  {
    company: '社外取締役\nマネックス証券株式会社',
    profile: images.chairmanProfile,
    title: '社外取締役',
    name: '広木 隆',
    position: '専門役員 チーフ・ストラテジスト',
    intro:
        '1963年東京生まれ。上智大学外国語学部卒。神戸大学大学院・経済学研究科博士後期課程修了。博士（経済学）。1987年、大和証券に入社。その後、ファンドマネジャーに転身。富士投信投資顧問、フィデリティ投信、JPモルガン・アセットマネジメントなど、国内系、外資系の資産運用会社において、運用や商品の組成に20年以上携わったほか自らヘッジファンドを立ち上げ運用した経験も有する。2010年より現職。'
  },
  {
    company: '社外取締役\nDe Capital 代表取締役',
    profile: images.oooProfile,
    title: '社外取締役',
    name: '吉田 知洋',
    position: '',
    intro:
        '1987年生まれ。アジア最大級の財閥であるCP Groupと日本政策投資銀行の共同投資ファンド（En Fund）、及び国内最大級のファンド運用会社である株式会社マーキュリアインベストメントを経て、De Capitalを創業。PE/VC/HF/Crypto等、多岐にわたる資産クラスにおいて卓越した投資実績を誇る。'
  }
]
